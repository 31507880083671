/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resguardActions, userActions } from '../../actions';
import moment from 'moment';
// core components
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import SideBar from "../../components/SideBar/SideBar"
import DataTable from 'react-data-table-component';
import { Button, Row, Table, Col, ListGroup, ListGroupItem, ListGroupItemText, Modal, Badge, Form, FormGroup, ListGroupItemHeading } from 'reactstrap';
//componente dataTable
import '../../assets/css/table.css';
import NumberFormat from 'react-number-format';
import { CSVLink } from "react-csv";
import '../../assets/css/filters.css';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import { useForm  } from "react-hook-form";
import { Icon } from '@iconify/react';
import fileDownload from '@iconify/icons-fa-solid/file-download';
import checkIcon from '@iconify/icons-fa-solid/check';
import timesIcon from '@iconify/icons-fa-solid/times';
import { Role, history } from '../../helpers';

function WithdrawalReportPage() {

  	useEffect(() => {
		document.body.classList.add("landing-page");
		document.body.classList.add("sidebar-collapse");
		document.documentElement.classList.remove("nav-open");
		return function cleanup() {
			document.body.classList.remove("landing-page");
			document.body.classList.remove("sidebar-collapse");
		};
	  });
	  
   
	//usuario
    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();

	const dataResguard = useSelector(state => state.resguard.data);
    const loadingPage = useSelector(state => state.resguard.loading);

	// Inicializar tabla sin data
	const [data, setData] = useState([]);

	//Verificar data de redux
	useEffect(() => {
		if(dataResguard && dataResguard.results){
            setData(dataResguard.results.sort((a,b) => {return a.lastWithdrawal.confirmationStatus - b.lastWithdrawal.confirmationStatus}));
		}
  	},[dataResguard]);
    
	const [rowCount, setRowCount] = useState(0);
	//Columnas Data table
	const columns = [
		{
			name: 'Sucursal',
			selector: 'lastWithdrawal.agency',
            sortable: true,
            wrap:true,
			cell : (row)=>{
				return  row.lastWithdrawal.agency ? row.lastWithdrawal.agency.name : ''
			},
		},
        {
			name: 'Bolivares',
			selector: 'lastWithdrawal.amountBs',
			sortable: true,
			cell : (row)=>{
				return  <NumberFormat value={row.lastWithdrawal.amountBs ? row.lastWithdrawal.amountBs.toFixed(2) : row.lastWithdrawal.amountBs} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} prefix='Bs ' />
			},
        },
        {
			name: 'Dólares',
			selector: 'lastWithdrawal.amountDollar',
			sortable: true,
			cell : (row)=>{
				return  <NumberFormat value={row.lastWithdrawal.amountDollar ? row.lastWithdrawal.amountDollar.toFixed(2) : row.lastWithdrawal.amountDollar} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} prefix='$ ' />
			},
        },
        {
			name: 'Euros',
			selector: 'lastWithdrawal.amountEur',
			sortable: true,
			cell : (row)=>{
				return  <NumberFormat value={row.lastWithdrawal.amountEur ? row.lastWithdrawal.amountEur.toFixed(2) : row.lastWithdrawal.amountEur} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} prefix='Eur ' />
			},
        },
        {
			name: 'Pesos',
			selector: 'lastWithdrawal.amountCop',
			sortable: true,
			cell : (row)=>{
				return  <NumberFormat value={row.lastWithdrawal.amountCop ? row.lastWithdrawal.amountCop.toFixed(2) : row.lastWithdrawal.amountCop} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} prefix='Cop ' />
			},
        },
        {
			name: 'Fecha',
			selector: 'lastWithdrawal.createdDate',
			sortable: true,
			cell : (row)=>{
				return row.lastWithdrawal.createdDate ? moment(row.lastWithdrawal.createdDate).utc().format('MMMM Do YYYY, h:mm:ss a') : '';
			},
        },
        {
			name: 'Status',
			selector: 'lastWithdrawal.confirmationStatus',
			sortable: true,
			cell : (row)=>{

				if(row.lastWithdrawal){
					return 	<Badge color={row.lastWithdrawal.confirmationStatus ? "success" : "danger"} pill className="h6 p-2 mt-1">
								{
									row.lastWithdrawal.confirmationStatus ? "CONFIRMADO" : "PENDIENTE"
								}
							</Badge>
				}
			},
        },
		{
			name: 'Confirmar',
			sortable: true,
			omit: user.role !== Role.Admin,	// Unicamente los administradores pueden realizar las confirmaciones de los retiros
			cell : (row)=>{
				if(row.lastWithdrawal && (user.role === Role.Admin)){
					return 	(row.lastWithdrawal.confirmationStatus === false) && <Button color="primary" type="submit" disabled={loadingPage} onClick={e => 
						{
							e.preventDefault(); 
							history.push('/resguard-confirm-withdrawal',{id:row.lastWithdrawal._id})
						}}>
								{loadingPage && <span className="spinner-border spinner-border-sm mr-1"></span>} Confirmar
							</Button>
				}
			},
		}
	];

	//obtener data de usuario necesaria
	const getUserData = () => {
		return {
            agency: user.agency.id,
            role:user.role,
            id: user.id,
            manager: true,
		}
	}

	//Consultar al entrar
	useEffect(() => {
		dispatch(resguardActions.resguardReport(getUserData(), {}));
	}, []);

	//Opciones de paginacion
	const paginationOptions = { rowsPerPageText: 'Filas por página', rangeSeparatorText: 'de', selectAllRowsItem: true, selectAllRowsItemText: 'Todos' };

	//Loader de la tabla
	const CustomLoader = () => (<><div className="loading-table"></div></>);

	//Form Data Filter
	const { handleSubmit, register, reset } = useForm();

	//Abrir/Cerrar filtros
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	//obtener sucursales para select
	const getting = useSelector(state => state.users.getting);
	const users = useSelector(state => state.users);

	useEffect(() => {
		dispatch(userActions.getListUserAgencies(getUserData()));
	},[]);

	const [listAgencies, setListAgencies] = useState(null);
	
	useEffect(() => {
		if(users.obtained){
			setListAgencies(users.list.agencies);
		}
	},[users.obtained]);



	const [filters, setFilters] = useState('');

	const handleChangeStartDate = (date) => {
		setStartDate(date);
	}

	const [startDate, setStartDate] = useState('');

	const clearFilters = () =>{
		setStartDate(''); 
		reset({agency:'', startDate:'', endDate:''})
	}

	//Modal genérico y mensaje
	const [modalWarning, setModalWarning] = useState(false);
	const [modalMsg, setModalMsg] = useState('');
	
	//Consultar por filtros
	const onFilterData = (data, e) => {
		var validStartDate =  moment(data.startDate).isValid();

		if(data.startDate != "" && !validStartDate){
			setModalWarning(true);
            setModalMsg('Ingrese una fecha válida');
			return;
		}

		setFilters(data);
		dispatch(resguardActions.resguardReport(getUserData(), data));
	}

	// En caso de que se quiera hacer un retiro
	
	const [dataWithdrawal, setDataWithdrawal] = useState(null);
	const [modalWithdrawal, setModalWithdrawal] = useState(false);

	const getWithdrawal = (data) => {

		setDataWithdrawal(data);
		setModalWithdrawal(true);
	}

	const confirmWithdrawal = () => {
		dispatch(resguardActions.resguardWithdrawal(getUserData()));
	}

	
	//Data al expandir una fila
	const ExpandedComponent = ({ data }) => {

		if(data.lastWithdrawal){

			return <ListGroup>
				<ListGroupItem>
					<ListGroupItemHeading>
					<b>Gerente que envió el dinero: </b>{ data.lastWithdrawal.user.firstName + ' ' +  data.lastWithdrawal.user.lastName}
					</ListGroupItemHeading>
					<ListGroupItemHeading>
						<b>Responsable de recibir el dinero: </b>{ data.lastWithdrawal.responsible }
					</ListGroupItemHeading>
					<ListGroupItemText>
						<b>Comentario: </b>{ data.lastWithdrawal.comment }
					</ListGroupItemText>
					<ListGroupItemHeading>
						<b>Usuario que confirmó: </b>{ data.lastWithdrawal.confirmationUser ? data.lastWithdrawal.confirmationUser.username : '' }
					</ListGroupItemHeading>
					<ListGroupItemHeading>
						<b>Fecha de Confirmación: </b>{ data.lastWithdrawal.confirmationUser ? moment(data.lastWithdrawal.confirmationDate).utc().format("YYYY-MM-DD hh:mm:ss a") : '' }
					</ListGroupItemHeading>
				</ListGroupItem>
			</ListGroup>
		}

	};

    return (
        <>
            <div className="d-flex" id="wrapper">
				<SideBar/>
				<div id="page-content-wrapper">
					<AdminNavbar/>
					<div className="flex-column flex-md-row p-3">

						<div className="d-flex justify-content-between" style={{padding:"4px 16px 4px 24px"}}>
							<div className="align-self-center">
								<h3 style={{ fontWeight:'bold',fontStyle: 'italic',  marginBottom: '0'}}>Retiros por confirmar</h3>
							</div>
						</div>
						{/* Filtros */}
						<div className="filter">
							<div className="d-flex justify-content-between">
								<a href="#" onClick={e => {e.preventDefault(); toggle() }}>
									<i className="fa fa-search" aria-hidden="true"></i> Búsqueda avanzada
								</a>
								{isOpen && <a href="#" onClick={e => { e.preventDefault();  clearFilters(); }}>
									<i className="fa fa-times" aria-hidden="true"></i> Borrar filtros
								</a>
								}	
							</div>
							{isOpen && <>
								<Form onSubmit={handleSubmit(onFilterData)} className="form-inline" style={{marginTop:15}}>
                                    {(user.role == 1 || user.role == 2 || user.role == 6 || user.role == 5) && <FormGroup className="mr-3">
                                            {getting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            <select className='form-control' name="agency"
                                                ref={register}>
                                                    <option key="" name="" value="">Seleccione sucursal</option>
                                                    {listAgencies && listAgencies.map(list => 
                                                        <option
                                                            key={list.id}
                                                            name={list.id}
                                                            value={list.id}>
                                                            {`${list.name}`}
                                                        </option>
                                                    )}
                                            </select>
                                        </FormGroup>
                                    }
									<Button color="primary" type="submit" disabled={loadingPage}>
										{loadingPage && <span className="spinner-border spinner-border-sm mr-1"></span>} Buscar
									</Button>
								</Form>
							</>
							}
						</div>
						{/* Filtros */}
						<Row>
							<Col>
							<DataTable
								className="dataTables_wrapper"
								responsive
								striped
								highlightOnHover
								expandableRows
								expandableRowsComponent={<ExpandedComponent />}
								sortIcon={ <i className="fa fa-arrow-down ml-2" aria-hidden="true"></i> }
								title="Ventas"
								progressPending={loadingPage}
								paginationComponentOptions={paginationOptions}
								progressComponent={<CustomLoader />}
								noDataComponent="No hay registros para mostrar"
								noHeader={true}
								columns={columns}
								data={data}
								pagination
								paginationServer
								paginationTotalRows={rowCount}
								persistTableHead
							/>
							</Col>
						</Row>
						<Modal toggle={() => {setModalWarning(false); setModalMsg('')}} isOpen={modalWarning}>
                            <div className="modal-header">
                            <h5 className="modal-title" id="examplemodalMsgLabel">
                                Ventas
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                type="button"
                                onClick={() =>  {setModalWarning(false); setModalMsg('')}}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                            </div>
                            <div className="modal-body">
                                <p>{modalMsg}</p>
                            </div>
                            <div className="modal-footer">
                            <Button
                                color="secondary"
                                type="button"
                                onClick={() =>  {setModalWarning(false); setModalMsg('')}}
                            >
                                Cerrar
                            </Button>
                            </div>
                        </Modal>
                        <Modal toggle={() => {setModalWithdrawal(false); setDataWithdrawal(null)}} isOpen={modalWithdrawal} className={"modal-lg"}>
                            <div className="modal-header">
                            <h5 className="modal-title" id="examplemodalMsgLabel">
                                ¿Confirmar retiro del resguardo?
                            </h5>
                            <button
                                aria-label="Close"
                                className="close"
                                type="button"
                                onClick={() =>  {setModalWithdrawal(false); setDataWithdrawal(null)}}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                            </div>
                            <div className="modal-body">
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>Bolívares</th>
                                            <th>Dólares</th>
                                            <th>Euros</th>
                                            <th>Pesos</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {dataWithdrawal && <tr>
                                        <td><NumberFormat value={dataWithdrawal.amountBsTotal} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} prefix='Bs ' /></td>
                                        <td><NumberFormat value={dataWithdrawal.amountDollarTotal} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} prefix='$ ' /></td>
                                        <td><NumberFormat value={dataWithdrawal.amountEurTotal} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} prefix='Eur ' /></td>
                                        <td><NumberFormat value={dataWithdrawal.amountCopTotal} displayType={'text'} thousandSeparator={','} decimalSeparator={'.'} prefix='Cop ' /></td>                                        
                                    </tr>
                                    }
                                    </tbody>
                                </Table>
                            </div>
                            <div className="modal-footer">
                                <Button color="primary" onClick={()=>confirmWithdrawal()}>
                                    Confirmar
                                </Button>
                                <Button color="secondary" type="button" onClick={() => {setModalWithdrawal(false);setDataWithdrawal(null);}}>
                                    Cerrar
                                </Button>
                            </div>
                        </Modal>
					</div>
				</div>
            </div>
        </>
    );
}

export default WithdrawalReportPage;