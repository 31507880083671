export const orderConstants = {
    //Crear
    ORDER_CREATE_REQUEST: 'ORDER_CREATE_REQUEST',
    ORDER_CREATE_SUCCESS: 'ORDER_CREATE_SUCCESS',
    ORDER_CREATE_FAILURE: 'ORDER_CREATE_FAILURE',

    ORDER_UPDATE_REQUEST: 'ORDER_UPDATE_REQUEST',
    ORDER_UPDATE_SUCCESS: 'ORDER_UPDATE_SUCCESS',
    ORDER_UPDATE_FAILURE: 'ORDER_UPDATE_FAILURE',

    ORDER_TABLE_REQUEST: 'ORDER_TABLE_REQUEST',
    ORDER_TABLE_SUCCESS: 'ORDER_TABLE_SUCCESS',
    ORDER_TABLE_FAILURE: 'ORDER_TABLE_FAILURE',

    ORDER_GET_REQUEST: 'ORDER_GET_REQUEST',
    ORDER_GET_SUCCESS: 'ORDER_GET_SUCCESS',
    ORDER_GET_FAILURE: 'ORDER_GET_FAILURE',
};
