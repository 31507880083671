//Tipos de salidas 
module.exports = {

    code: {
        banesco:1,
        provincial: 2,
        venezuela: 3,
        bicentenario: 4,
        mercantil: 5,
        bod: 6,
    },

    bank: {
        1:'BANESCO',
        2:'PROVINCIAL', 
        3:'VENEZUELA',
        4:'BICENTENARIO',
        5:'MERCANTIL',
        6:'BOD',
    },

    account: {
        1:'PERSONAL',
        2:'FISCAL',
        3:'NO FISCAL',
        4:'PRESTADO',
        5:'EMPRESARIAL',
        6:'ALQUILADO',
        7:'PROPIEDAD DEL BANCO',
        8:'PUNTO EXTERNO',
    },
}